import React from "react";

const OptionsList = ({ option, textColour }) => {
  return (
    <React.Fragment>
      <p className="mb-0">
        {option.key !== "Make it a Meal!" && option.key !== "Notes to the Chef:" && (
          <React.Fragment>
            <strong>{option.key} </strong>
            <span className={textColour}>{option.value.replace(/ *\([^)]*\) */g, "").replace("&amp;", "&")}</span>
          </React.Fragment>
        )}
        {option.key === "Notes to the Chef:" && (
          <React.Fragment>
            <strong>{option.key} </strong>
            <span className={textColour}>{option.value}</span>
          </React.Fragment>
        )}
      </p>
    </React.Fragment>
  );
};

export default OptionsList;
