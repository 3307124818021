const config = {
	//server
	serverUrlStart: "https://kporder.pizzapalace.com.au/kporderapi",
	//serverUrlStart: "http://192.168.1.112:5002",

	//UrlStart: "/pporder"

	//server and home
	UrlStart: "",
};

module.exports = config;
