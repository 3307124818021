import React, { useEffect } from "react";

const OrderRow = ({ order, selectedOrderFunc, selectedOrderId }) => {
	const [orderRowStyle, setOrderRowStyle] = React.useState({});

	useEffect(() => {
		function orderStyleCalc(order) {
			let res = {};
			res.pizzaSection = sectionTest("pizzaSection", order);
			res.grillSection = sectionTest("grillSection", order);
			res.kebabSection = sectionTest("kebabSection", order);
			setOrderRowStyle(res);
		}
		orderStyleCalc(order);
	}, [order]);

	function orderStatStyle(order) {
		if (order.status === "processing" && order.totalMinutesToPickUp >= 45) {
			return "list-group-item-secondary";
		} else if (order.status === "processing" && order.totalMinutesToPickUp < 45 && order.totalMinutesToPickUp >= 0) {
			return "list-group-item-warning";
		} else if (order.status === "processing" && order.totalMinutesToPickUp < 0) {
			return "list-group-item-danger";
		} else if (order.status === "preparing") {
			return "list-group-item-info";
		} else if (order.status === "ready") {
			return "list-group-item-primary";
		} else if (order.status === "preparing-pending") {
			return "list-group-item-success";
		}
	}

	function orderActiveStyle(id, selectedOrderId) {
		if (id === selectedOrderId) {
			return " active";
		} else {
			return "";
		}
	}

	function sectionTest(section, order) {
		if (order[section] !== undefined) {
			if (order[section].length === 0) {
				return "opacity-3";
			} else {
				return "";
			}
		}
	}

	const handleClick = (id) => {
		selectedOrderFunc(id);
	};

	return (
		<button type="button" className={"list-group-item list-group-item-action py-0 " + orderStatStyle(order) + orderActiveStyle(order.id, selectedOrderId)} onClick={() => handleClick(order.id)}>
			<div className="row d-flex align-items-center">
				<div className="col-sm-3">
					<h2>#{order.id}</h2>
				</div>
				<div className="col-sm-4 ">
					<h5 className="mt-2 ">
						{order.status === "processing" && order.daysToPickUp !== 0 && `${order.daysToPickUp} Days ${order.hoursToPickUp} Hrs`}
						{order.status === "processing" && order.daysToPickUp === 0 && order.hoursToPickUp !== 0 && `${order.hoursToPickUp} Hrs ${order.minutesToPickUp} Mins`}
						{order.status === "processing" && order.daysToPickUp === 0 && order.hoursToPickUp === 0 && `${order.minutesToPickUp} Mins`}

						{order.status === "preparing-pending" && `-`}
					</h5>
					{order.status === "preparing" && (
						<p className="mt-3 ">
							{`Prepping for `}
							<strong>{`${Math.floor((Date.now() - order.dateMod) / (1000 * 60))}`}</strong>
							{" Mins"}
						</p>
					)}
					{order.status === "ready" && (
						<p className="mt-3 ">
							{`Ready for `}
							<strong>{`${Math.floor((Date.now() - order.dateMod) / (1000 * 60))}`}</strong>
							{" Mins"}
						</p>
					)}
				</div>
				<div className="col-sm-2">
					<div className="row d-flex align-items-center">
						<div className={"col-sm-4 d-flex justify-content-center py-3 bg-dark text-light " + orderRowStyle.kebabSection}>K</div>
						<div className={"col-sm-4 d-flex justify-content-center py-3 bg-dark text-light " + orderRowStyle.pizzaSection}>P</div>
						<div className={"col-sm-4 d-flex justify-content-center py-3 bg-dark text-light " + orderRowStyle.grillSection}>G</div>
					</div>
				</div>
				<div className="col-sm-3">
					<h5 className="mt-2 ">{order.FormatedStatus}</h5>
				</div>
			</div>
		</button>
	);
};

export default OrderRow;
