import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "joi-browser";
import Form from "./common/form";
import auth from "../services/authService";

class logIn extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {},
    loginError: { msg: "", errorClass: "invisible" }
  };

  schema = {
    username: Joi.string().required().email().max(98).label("Username"),
    password: Joi.string().required().max(100).label("Password")
  };

  doSubmit = async () => {
    // Call the server
    let loginError = { ...this.state.loginError };
    loginError.msg = "";
    loginError.errorClass = "invisible";
    this.setState({ loginError });

    try {
      const { data } = this.state;
      await auth.login(data.username, data.password);

      const { state } = this.props.location;
      window.location = state ? state.from.pathname : "/";
    } catch (ex) {
      console.log(ex.message);
      if (ex.response || ex.message) {
        let loginError = { ...this.state.loginError };
        if (ex.response) {
          loginError.msg = ex.response.data;
        }
        if (ex.message) {
          loginError.msg = ex.message;
        }
        loginError.errorClass = "visible";
        this.setState({ loginError });
      }
    }

    console.log("Submitted");
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/" />;

    return (
      <div>
        <h1>Login</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("username", "Username")}
          {this.renderInput("password", "Password", "password")}
          {this.renderButton("Login")}
        </form>
        <div className={`alert alert-danger mt-3 ${this.state.loginError.errorClass}`}>{this.state.loginError.msg}</div>
      </div>
    );
  }
}

export default logIn;
