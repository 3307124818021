import React, { useRef } from "react";
import ItemsList from "../components/itemsList";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";

import logo from "../img/Logo.svg";

const SingleOrderDisp = ({ selectedOrder, acceptOrderFunc }) => {
	const [showWait, setShowWait] = React.useState(false);
	const componentRef = useRef();

	function orderStatStyle(selectedOrder) {
		if (selectedOrder.status === "processing" && selectedOrder.totalMinutesToPickUp >= 30) {
			return "card border-secondary ";
		} else if (selectedOrder.status === "processing" && selectedOrder.totalMinutesToPickUp < 30 && selectedOrder.totalMinutesToPickUp >= 0) {
			return "card border-warning ";
		} else if (selectedOrder.status === "processing" && selectedOrder.totalMinutesToPickUp < 0) {
			return "card border-danger ";
		} else if (selectedOrder.status === "preparing") {
			return "card border-info ";
		} else if (selectedOrder.status === "ready") {
			return "card border-primary ";
		} else if (selectedOrder.status === "preparing-pending") {
			return "card border-success ";
		}
	}

	function orderCustNoteStyle(customer_note) {
		if (customer_note !== "" && customer_note !== undefined) {
			return "card border-danger ";
		} else {
			return "card border ";
		}
	}

	function mealDealStyle(mealDealCount) {
		if (mealDealCount > 0) {
			return "card bg-danger text-white";
		} else {
			return "card bg-light text-dark";
		}
	}

	const handleAcceptClick = (id) => {
		acceptOrderFunc(id, "preparing", "Preparing");

		handlePrint();
		setShowWait(true);
		setTimeout(function () {
			setShowWait(false);
		}, 6000);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<React.Fragment>
			<div className="row ">
				<div className="col-md-4">
					<div className="row mb-2">
						<div className="col-sm-7  pl-3 pr-1 ">
							{selectedOrder.status === "processing" && showWait === true && (
								<React.Fragment>
									<button type="button" className="btn btn-success  btn-lg btn-block py-3" disabled>
										<Loader className="py-0" type="ThreeDots" color="white" height={20} width={100} timeout={10000} />
									</button>
								</React.Fragment>
							)}
							{selectedOrder.status === "processing" && showWait === false && (
								<button type="button" className="btn btn-success  btn-lg btn-block py-3" onClick={() => handleAcceptClick(selectedOrder.id)}>
									<i className="fas fa-vote-yea"></i> Accept Ord.
								</button>
							)}
							{selectedOrder.status !== "processing" && (
								<button type="button" className="btn btn-success  btn-lg btn-block py-3" disabled>
									<i className="fas fa-vote-yea"></i> Accept Ord.
								</button>
							)}
						</div>
						<div className="col-sm-5  pr-3 pl-1 ">
							<button type="button" onClick={handlePrint} className="btn btn-outline-secondary  btn-lg btn-block py-3">
								<i className="fas fa-print"></i> Print
							</button>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-5 pr-1 pl-3">
							<div className="card text-white bg-secondary mb-2 ">
								<div className="card-body py-1 px-2">
									<h5 className="card-title my-0">
										<span style={{ fontWeight: "bolder" }}>Ord. # </span>
										<span>
											{(selectedOrder.id === undefined || selectedOrder.id === "") && ` --`}
											{selectedOrder.id !== undefined && `${selectedOrder.id}`}
										</span>
									</h5>
								</div>
							</div>
						</div>
						<div className="col-sm-7 pl-1 pr-3">
							<div className={"card border mb-2 " + orderStatStyle(selectedOrder)}>
								<div className="card-body py-1 px-2">
									<span style={{ fontWeight: "bolder" }}>Ord. Status </span>
									<span>
										{(selectedOrder.FormatedStatus === undefined || selectedOrder.FormatedStatus === "") && ` --`}
										{selectedOrder.FormatedStatus !== undefined && `${selectedOrder.FormatedStatus}`}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-8 pr-1 pl-3">
							<div className="card border mb-2">
								<div className="card-body py-1 px-2">
									<span style={{ fontWeight: "bolder" }}>Pick Up: </span>
									<span>
										{(selectedOrder.formatedOrderDate === undefined || selectedOrder.formatedOrderDate === "") && ` --`}
										{selectedOrder.formatedOrderDate !== undefined && `${selectedOrder.formatedOrderDate}`}
									</span>
								</div>
							</div>
						</div>
						<div className="col-sm-4 pl-1 pr-3">
							<div className="card border mb-2">
								<div className="card-body py-1 px-2">
									<span style={{ fontWeight: "bolder" }}>Total </span>
									<span>
										${(selectedOrder.total === undefined || selectedOrder.total === "") && ` --`}
										{selectedOrder.total !== undefined && `${selectedOrder.total}`}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-6 pr-1 pl-3">
							<div className={"mb-2 " + mealDealStyle(selectedOrder.mealBotOpt)}>
								<div className="card-body px-1 p-0 overflow-hidden text-nowrap">
									<span style={{ fontWeight: "bolder" }}>600ml Meal Deal: </span>

									<span className="card-text my-0">
										{(selectedOrder.mealBotOpt === undefined || selectedOrder.mealBotOpt === 0) && ` --`}
										{selectedOrder.mealBotOpt !== 0 && selectedOrder.mealBotOpt !== undefined && `${selectedOrder.mealBotOpt}`}
									</span>
								</div>
							</div>
						</div>
						<div className="col-sm-6 pl-1 pr-3">
							<div className={"mb-2 " + mealDealStyle(selectedOrder.mealCanOpt)}>
								<div className="card-body px-1 p-0 overflow-hidden text-nowrap">
									<span style={{ fontWeight: "bolder" }}>Can Meal Deal: </span>
									<span className="card-text my-0">
										{(selectedOrder.mealCanOpt === undefined || selectedOrder.mealCanOpt === 0) && ` --`}
										{selectedOrder.mealCanOpt !== 0 && selectedOrder.mealCanOpt !== undefined && `${selectedOrder.mealCanOpt}`}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex align-items-stretch" style={{ height: "85px" }}>
						<div className={"mb-2 flex-fill " + orderCustNoteStyle(selectedOrder.customer_note)}>
							<div className="card-header px-1 p-0 border border-top-0 border-end-0 border-start-0">Customer Notes</div>
							<div className="card-body text-danger px-1 p-0 overflow-auto">
								<p className="card-text my-0 font-weight-bold ">
									{(selectedOrder.customer_note === undefined || selectedOrder.customer_note === "") && ` `}
									{selectedOrder.customer_note !== undefined && `${selectedOrder.customer_note}`}
								</p>
							</div>
						</div>
					</div>
					<div className="card bg-light mb-2">
						<div className="card-body px-1 p-0 overflow-hidden text-nowrap">
							<span style={{ fontWeight: "bolder" }}>First Name: </span>
							<span className="card-text my-0">
								{(selectedOrder.first_name === undefined || selectedOrder.first_name === "") && ` --`}
								{selectedOrder.first_name !== undefined && `${selectedOrder.first_name}`}
							</span>
						</div>
					</div>
					<div className="card bg-light mb-2">
						<div className="card-body px-1 p-0 overflow-hidden text-nowrap">
							<span style={{ fontWeight: "bolder" }}>Last Name: </span>
							<span className="card-text my-0">
								{(selectedOrder.last_name === undefined || selectedOrder.last_name === "") && ` --`}
								{selectedOrder.last_name !== undefined && `${selectedOrder.last_name}`}
							</span>
						</div>
					</div>
					<div className="card bg-light mb-2">
						<div className="card-body px-1 p-0 overflow-hidden text-nowrap">
							<span style={{ fontWeight: "bolder" }}>Email: </span>
							<span className="card-text my-0">
								{(selectedOrder.email === undefined || selectedOrder.email === "") && ` --`}
								{selectedOrder.email !== undefined && `${selectedOrder.email}`}
							</span>
						</div>
					</div>

					<div className="card bg-light mb-2">
						<div className="card-body px-1 p-0 overflow-hidden text-nowrap">
							<span style={{ fontWeight: "bolder" }}>Phone: </span>
							<span className="card-text my-0">
								{(selectedOrder.phone === undefined || selectedOrder.phone === "") && ` --`}
								{selectedOrder.phone !== undefined && `${selectedOrder.phone}`}
							</span>
						</div>
					</div>
				</div>
				<div className="col-md-8">
					<div className="row">
						<div className="col-md-4 pt-0 pb-1 px-2 ">
							<div className="card border-dark mb-3">
								<div className="card-header bg-dark text-white py-1">Kebab Section</div>
								<div className="card-body py-0 pl-2 pr-0">
									<div className="card-text py-1 pr-2 OrdersDetailsText" style={{ fontSize: "10pt" }}>
										{(selectedOrder.kebabSection === undefined || selectedOrder.kebabSection === "") && ` --`}
										{selectedOrder.kebabSection !== undefined &&
											selectedOrder.kebabSection &&
											selectedOrder.kebabSection.map((item, index) => (
												<ItemsList key={index} item={item} maxWidth="350px" textColour="text-danger" bgColour="bg-light" badgeDark="badge-dark" badgeRed="badge-danger"></ItemsList>
											))}
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 pt-0 pb-1 px-2 ">
							<div className="card border-dark mb-3">
								<div className="card-header bg-dark text-white py-1">Pizza Section</div>
								<div className="card-body py-0 pl-2 pr-0">
									<div className="card-text py-1 pr-2 OrdersDetailsText" style={{ fontSize: "10pt" }}>
										{(selectedOrder.pizzaSection === undefined || selectedOrder.pizzaSection === "") && ` --`}
										{selectedOrder.pizzaSection !== undefined &&
											selectedOrder.pizzaSection &&
											selectedOrder.pizzaSection.map((item, index) => (
												<ItemsList key={index} item={item} maxWidth="350px" textColour="text-danger" bgColour="bg-light" badgeDark="badge-dark" badgeRed="badge-danger"></ItemsList>
											))}
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 pt-0 pb-1 px-2 ">
							<div className="card border-dark mb-3">
								<div className="card-header bg-dark text-white py-1">Grill Section</div>
								<div className="card-body py-0 pl-2 pr-0">
									<div className="card-text py-1 pr-2 OrdersDetailsText" style={{ fontSize: "10pt" }}>
										{(selectedOrder.grillSection === undefined || selectedOrder.grillSection === "") && ` --`}
										{selectedOrder.grillSection !== undefined &&
											selectedOrder.grillSection &&
											selectedOrder.grillSection.map((item, index) => (
												<ItemsList key={index} item={item} maxWidth="350px" textColour="text-danger" bgColour="bg-light" badgeDark="badge-dark" badgeRed="badge-danger"></ItemsList>
											))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<React.Fragment>
				<div style={{ display: "none" }}>
					<div ref={componentRef}>
						<div className="Invoice-Container">
							{selectedOrder.id !== undefined && (
								<React.Fragment>
									<img src={logo} alt="Logo" style={{ width: "70mm", marginLeft: "8mm", marginBottom: "5mm" }} />
									<h3>TAX INVOICE</h3>
									<p>
										SUPER KEBABS AND PIDE HOUSE PTY LTD
										<br />
										ABN: 50 612 642 243
										<br />
										P: (07) 3349 4545
										<br />
										A: 23/2120 Logan Rd,
										<br />
										Mount Gravatt QLD 4122
										<br />
										W: www.kebabpalace.com.au
									</p>
									<h5>
										Order Number: <strong>#{selectedOrder.id}</strong>
									</h5>
									<p>
										Order Pick Up Date: {selectedOrder.formatedOrderDate}
										<br />
										For: {selectedOrder.first_name} {selectedOrder.last_name}
									</p>

									{selectedOrder.mealBotOpt > 0 && (
										<h5>
											<div className="badge badge-pill badge-light text-danger border border-danger">{selectedOrder.mealBotOpt}x 600ml Drink / Chips Meal Deals</div>
										</h5>
									)}
									{selectedOrder.mealCanOpt > 0 && (
										<h5>
											<div className="badge badge-pill badge-light text-danger border border-danger">{selectedOrder.mealCanOpt}x Soft Drink Can / Chips Meal Deals</div>
										</h5>
									)}
									{selectedOrder.customer_note !== "" && selectedOrder.customer_note !== undefined && (
										<React.Fragment>
											<h5>
												<div style={{ overflowWrap: "break-word" }} className="badge badge-pill badge-light text-danger border border-danger">
													Customer Note:{" "}
												</div>
											</h5>
											<p>{selectedOrder.customer_note}</p>
										</React.Fragment>
									)}
									{selectedOrder.pizzaSection !== undefined &&
										selectedOrder.pizzaSection.map((item, index) => (
											<div key={index}>
												{item.quantity}x {item.name} <span className="float-right">${item.total}</span>
											</div>
										))}
									{selectedOrder.grillSection !== undefined &&
										selectedOrder.grillSection.map((item, index) => (
											<div key={index}>
												{item.quantity}x {item.name} <span className="float-right">${item.total}</span>
											</div>
										))}
									{selectedOrder.kebabSection !== undefined &&
										selectedOrder.kebabSection.map((item, index) => (
											<div key={index}>
												{item.quantity}x {item.name} <span className="float-right">${item.total}</span>
											</div>
										))}
									<h5>
										<span className="float-right">GST ${(selectedOrder.total * 0.1).toFixed(2)}</span>
										<br />
										<span className="float-right">TOTAL (inc GST) ${selectedOrder.total}</span>
									</h5>
									<p>
										<br />
									</p>
								</React.Fragment>
							)}
							<div className="pagebreak"> </div>
							{selectedOrder.kebabSectionSequance > 0 && (
								<React.Fragment>
									<h2>KEBAB SECTION</h2>
									<h3>
										Order# {selectedOrder.id} --- [{selectedOrder.kebabSectionSequance}/{selectedOrder.totalSectionCount}]
									</h3>
									{(selectedOrder.kebabSection === undefined || selectedOrder.kebabSection === "") && ` --`}
									{selectedOrder.kebabSection !== undefined &&
										selectedOrder.kebabSection &&
										selectedOrder.kebabSection.map((item, index) => (
											<ItemsList
												key={index}
												item={item}
												maxWidth="100mm"
												textColour="text-dark"
												bgColour="bg-white"
												badgeDark="badge-light text-dark border border-dark"
												badgeRed="badge-light text-danger border border-danger"
											></ItemsList>
										))}
								</React.Fragment>
							)}
							<div className="pagebreak"> </div>
							{selectedOrder.pizzaSectionSequance > 0 && (
								<React.Fragment>
									<h2>PIZZA SECTION</h2>
									<h3>
										Order# {selectedOrder.id} --- [{selectedOrder.pizzaSectionSequance}/{selectedOrder.totalSectionCount}]
									</h3>
									{(selectedOrder.pizzaSection === undefined || selectedOrder.pizzaSection === "") && ` --`}
									{selectedOrder.pizzaSection !== undefined &&
										selectedOrder.pizzaSection &&
										selectedOrder.pizzaSection.map((item, index) => (
											<ItemsList
												key={index}
												item={item}
												maxWidth="100mm"
												textColour="text-dark"
												bgColour="bg-white"
												badgeDark="badge-light text-dark border border-dark"
												badgeRed="badge-light text-danger border border-danger"
											></ItemsList>
										))}
								</React.Fragment>
							)}
							<div className="pagebreak"> </div>
							{selectedOrder.grillSectionSequance > 0 && (
								<React.Fragment>
									<h2>GRILL SECTION</h2>
									<h3>
										Order# {selectedOrder.id} --- [{selectedOrder.grillSectionSequance}/{selectedOrder.totalSectionCount}]
									</h3>
									{(selectedOrder.grillSection === undefined || selectedOrder.grillSection === "") && ` --`}
									{selectedOrder.grillSection !== undefined &&
										selectedOrder.grillSection &&
										selectedOrder.grillSection.map((item, index) => (
											<ItemsList
												key={index}
												item={item}
												maxWidth="100mm"
												textColour="text-dark"
												bgColour="bg-white"
												badgeDark="badge-light text-dark border border-dark"
												badgeRed="badge-light text-danger border border-danger"
											></ItemsList>
										))}
								</React.Fragment>
							)}
						</div>
					</div>
				</div>
			</React.Fragment>
		</React.Fragment>
	);
};

export default SingleOrderDisp;
