import React from "react";
import OptionsList from "../components/optionsList";

const ItemsList = ({ item, maxWidth, textColour, bgColour, badgeDark, badgeRed }) => {
  return (
    <React.Fragment>
      <div className="card border-dark mt-2 mb-3" style={{ maxWidth: maxWidth }}>
        <div className={"card-header py-0 px-2 " + bgColour}>
          <h5 className="my-2 py-0">
            {item.quantity === 1 && <span className={"badge badge-pill " + badgeDark}>{item.quantity}x </span>}
            {item.quantity !== 1 && <span className={"badge badge-pill " + badgeRed}>{item.quantity}x </span>} <span className={"badge badge-pill " + badgeDark}>{item.name}</span>
            <span className="float-right">{item.mealOption !== "" && <span className={"badge badge-pill " + badgeRed}>{item.mealOption}</span>}</span>
          </h5>
        </div>
        <div className="card-body text-dark pt-1 pb-2 px-2">{item.options && item.options.map((option, index) => <OptionsList key={index} option={option} textColour={textColour}></OptionsList>)}</div>
      </div>
    </React.Fragment>
  );
};

export default ItemsList;
