import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import { toast } from "react-toastify";
import http from "./httpService";
import { serverUrlStart } from "../config/config";

const api = new WooCommerceRestApi({
	url: "https://kebabpalace.com.au",
	consumerKey: process.env.REACT_APP_CONSUMERKEY,
	consumerSecret: process.env.REACT_APP_CONSUMERSECRET,
	version: "wc/v3",
	queryStringAuth: true,
	axiosConfig: {
		headers: {
			"content-type": "application/json",
		},
	},
});

class OrderService {
	async getOrders() {
		try {
			let { data: ordersProcessing } = await api.get("orders", { status: "processing", per_page: 100 });
			let { data: ordersReady } = await api.get("orders", { status: "ready", per_page: 100 });
			let { data: ordersPreparing } = await api.get("orders", { status: "preparing", per_page: 100 });
			let { data: ordersDone } = await api.get("orders", { status: "preparing-pending", per_page: 20 });

			let allOrders = [...ordersProcessing, ...ordersDone, ...ordersPreparing, ...ordersReady];

			let response = [];
			for (let i = 0; i < allOrders.length; i++) {
				response[i] = this.transOrder(allOrders[i]);
			}

			this.sortOrder(response);

			return response;
		} catch (error) {
			console.log(error);
			return [{}];
		}
	}

	async getLocOrders() {
		try {
			let allOrders = [];
			let { data: ordersPreparing } = await http.get(serverUrlStart + "/orders/search/order_status/preparing");
			let { data: ordersReady } = await http.get(serverUrlStart + "/orders/search/order_status/ready");

			if (Object.keys(ordersPreparing[0]).length > 0 && Object.keys(ordersReady[0]).length > 0) {
				allOrders = [...ordersPreparing, ...ordersReady];
			} else if (Object.keys(ordersPreparing[0]).length > 0 && Object.keys(ordersReady[0]).length === 0) {
				allOrders = [...ordersPreparing];
			} else if (Object.keys(ordersPreparing[0]).length === 0 && Object.keys(ordersReady[0]).length > 0) {
				allOrders = [...ordersReady];
			} else {
				allOrders = [{}];
			}

			let response = [];
			for (let i = 0; i < allOrders.length; i++) {
				response[i] = this.transLocalOrder(allOrders[i]);
			}

			return response;
		} catch (error) {
			console.log(error);
			return [{}];
		}
	}

	async acceptOrder(orders, id, status, FormatedStatus) {
		const orderById = (order) => order.id === id;
		let response = [...orders];
		let orderByIdIndex = response.findIndex(orderById);
		try {
			let res = await api.put(`orders/${id}`, { status: status });

			response[orderByIdIndex].status = status;
			response[orderByIdIndex].FormatedStatus = FormatedStatus;
			response[orderByIdIndex].dateMod = Date.parse(res.data.date_modified);
			return response;
		} catch (error) {
			console.log(error);
			return null;
		}
	}

	async acceptLocalOrder(orders, id, status, FormatedStatus) {
		const orderById = (order) => order.id === id;
		let response = [...orders];
		let orderByIdIndex = response.findIndex(orderById);
		try {
			if (status === "ready") {
				let res = await http.put(serverUrlStart + "/orders/update", { order_number: id.toString(), order_status: status, order_date: Date.now().toString() });
				response[orderByIdIndex].status = status;
				response[orderByIdIndex].FormatedStatus = FormatedStatus;
				response[orderByIdIndex].dateMod = parseInt(res.data.order_date);
				return response;
			} else if (status === "done") {
				await http.put(serverUrlStart + "/orders/delete", { order_number: id.toString() });
				if (response.length > 1) {
					response.splice(orderByIdIndex, 1);
					return response;
				} else {
					return [{}];
				}
			} else {
				return null;
			}
		} catch (error) {
			console.log(error);
			return null;
		}
	}

	async addLocOrder(id) {
		try {
			const { data } = await http.post(serverUrlStart + "/orders/new", { order_number: id.toString(), order_status: "ready", order_date: Date.now().toString() });
			data.id = parseInt(data.order_number);
			data.dateMod = parseInt(data.order_date);
			data.status = data.order_status;
			data.FormatedStatus = "Ready";
			data.local = true;
			return data;
		} catch (error) {
			toast.error(error.response.data, { position: "top-left" });
			return null;
		}
	}

	addSingleOrderOnly(givenOrders, order, notif) {
		let response = [...givenOrders];
		if (order.status === "preparing-pending" || order.status === "processing" || order.status === "ready" || order.status === "preparing") {
			response[response.length] = this.transOrder(order);
			if (notif === true) {
				this.sortOrder(response);
				toast.success(`New Order!!! Order#${order.id}`, {
					position: "bottom-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
				});
			}
			return response;
		} else {
			return null;
		}
	}

	async fetchOrderByIdOneByOne(givenOrders, id, notif) {
		try {
			let { data: orders } = await api.get(`orders/${id}`);
			if (orders.status === "processing") {
				givenOrders[givenOrders.length] = this.transOrder(orders);
				if (notif === true) {
					toast.success(`New Order!!! Order#${orders.id}`, {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
					});
				}
				return givenOrders;
			}
		} catch (error) {
			return null;
		}
	}

	async addRangeOfOrders(givenOrders, fromOrderNum, toOrderNum, notif) {
		for (let i = fromOrderNum; i < toOrderNum + 1; i++) {
			//   console.log("Multi Order Add", i);

			if (this.getOrderById(givenOrders, i) === undefined) {
				await this.fetchOrderByIdOneByOne(givenOrders, i, notif);
			}
		}

		this.sortOrder(givenOrders);
	}

	async addOneOrder(givenOrders, order, notif = true) {
		let orderUpdated = false;
		if (order.status === undefined || order.id === undefined) {
			return { newOrder: null, orderUpdated: orderUpdated };
		}

		if (Object.entries(givenOrders[0]).length === 0) {
			//  console.log("Way 1 No orders Stored", order.id);
			return { newOrder: this.addSingleOrderOnly([], order, notif), orderUpdated: orderUpdated };
		}

		let givenOrdersCopy = [...givenOrders];
		givenOrdersCopy.sort(function (a, b) {
			return b.id - a.id;
		});

		let latestOrderNumber = givenOrdersCopy[0].id;
		let existingOrder = this.getOrderById(givenOrders, order.id);

		if (latestOrderNumber + 1 === order.id && existingOrder === undefined) {
			// console.log("Way 2 1 Order Number Diff", latestOrderNumber, order.id);
			return { newOrder: this.addSingleOrderOnly(givenOrders, order, notif), orderUpdated: orderUpdated };
		} else if (latestOrderNumber + 1 === order.id && existingOrder !== undefined) {
			orderUpdated = true;
			return { newOrder: this.updateOrder(givenOrders, order), orderUpdated: orderUpdated };
		} else if (existingOrder === undefined) {
			// console.log("Way 3 More Order Numbers Diff", latestOrderNumber, order.id);
			await this.addRangeOfOrders(givenOrders, latestOrderNumber + 1, order.id - 1, notif);
			return { newOrder: this.addSingleOrderOnly(givenOrders, order, notif), orderUpdated: orderUpdated };
		} else {
			orderUpdated = true;
			await this.addRangeOfOrders(givenOrders, latestOrderNumber + 1, order.id - 1, notif);
			return { newOrder: this.updateOrder(givenOrders, order), orderUpdated: orderUpdated };
		}
	}

	addOneLocalOrder(givenOrders, neworder) {
		const orderById = (order) => order.id === parseInt(neworder.order_number);
		let response = [...givenOrders];
		let orderByIdIndex = response.findIndex(orderById);
		if (orderByIdIndex < 0) {
			if (Object.keys(response[0]).length > 0) {
				let newLocOrder = [...response, this.transLocalOrder(neworder)];
				return newLocOrder;
			} else {
				return [this.transLocalOrder(neworder)];
			}
		} else if (orderByIdIndex >= 0 && (neworder.action === "new" || neworder.action === "update")) {
			response.splice(orderByIdIndex, 1);

			if (Object.keys(response[0]).length > 0) {
				let newLocOrder = [...response, this.transLocalOrder(neworder)];
				return newLocOrder;
			} else {
				return [this.transLocalOrder(neworder)];
			}
		} else if (orderByIdIndex >= 0 && neworder.action === "delete") {
			if (response.length > 1) {
				response.splice(orderByIdIndex, 1);
				return response;
			} else {
				return [{}];
			}
		} else {
			return null;
		}
	}
	updateOrder(orders, newOrder) {
		const orderById = (order) => order.id === newOrder.id;
		let response = [...orders];
		let orderByIdIndex = response.findIndex(orderById);
		response.splice(orderByIdIndex, 1);

		if (newOrder.status === "preparing-pending" || newOrder.status === "processing" || newOrder.status === "ready" || newOrder.status === "preparing") {
			response[response.length] = this.transOrder(newOrder);

			this.sortOrder(response);
			return response;
		} else {
			return null;
		}
	}

	async fetchOrderById(givenOrders, id) {
		try {
			let { data: orders } = await api.get(`orders/${id}`);
			if (orders.status !== "preparing-pending" && orders.status !== "processing" && orders.status !== "ready" && orders.status !== "preparing") {
				throw new Error();
			}
			let response = [...givenOrders];

			response[response.length] = this.transOrder(orders);
			response[response.length - 1].searched = true;

			this.sortOrder(response);

			return response;
		} catch (error) {
			toast.error(`Order# ${id} Not Found!`, { position: "top-left" });

			return null;
		}
	}

	sortOrder(response) {
		response.sort(function (a, b) {
			return b.id - a.id;
		});

		response.sort(function (a, b) {
			return b.dateMod - a.dateMod;
		});

		response.sort(function (a, b) {
			if (a.status === "ready") {
				return -1;
			}
			return 0;
		});

		response.sort(function (a, b) {
			if (a.status === "preparing") {
				return -1;
			}
			return 0;
		});

		response.sort(function (a, b) {
			if (a.status === "processing") {
				return -1;
			}
			return 0;
		});

		response.sort(function (a, b) {
			if (b.status === "processing" && a.status === "processing") {
				return a.totalMinutesToPickUp - b.totalMinutesToPickUp;
			}
			return 0;
		});
	}

	getOrderById(orders, id) {
		if (typeof orders !== "undefined" && orders.length > 0) {
			let response = orders.filter((order) => order.id === id);
			return response[0];
		}
	}

	refreshOrderTimes(orders) {
		let response = [...orders];

		for (let i = 0; i < orders.length; i++) {
			let { daysToPickUp, hoursToPickUp, minutesToPickUp, totalMinutesToPickUp, formatedOrderDate } = this.pickUpTimeOrder(orders[i].pickup_time);

			response[i].daysToPickUp = daysToPickUp;
			response[i].hoursToPickUp = hoursToPickUp;
			response[i].minutesToPickUp = minutesToPickUp;
			response[i].totalMinutesToPickUp = totalMinutesToPickUp;
			response[i].formatedOrderDate = formatedOrderDate;
		}

		this.sortOrder(response);

		let counter = 0;
		let responseOut = [];
		let slicedRes = [];
		let searchedOrders = [];

		for (let i = 0; i < response.length; i++) {
			if (response[i].searched === true) {
				searchedOrders[counter] = response[i];
				counter++;
			}
		}
		counter = 0;
		for (let i = 0; i < response.length; i++) {
			if (response[i].status === "preparing-pending") {
				counter = counter + 1;
				if (counter === 40) {
					slicedRes = response.slice(0, i + 1);

					if (searchedOrders.length > 0) {
						responseOut = [...slicedRes, ...searchedOrders];
						this.sortOrder(responseOut);
					} else {
						responseOut = [...slicedRes];
					}

					break;
				}
			}
		}

		if (responseOut.length === 0) {
			responseOut = [...response];
		}

		let alarm = false;
		for (let i = 0; i < responseOut.length; i++) {
			if (response[i].status === "processing" && response[i].totalMinutesToPickUp < 30) {
				alarm = true;
				break;
			}
		}

		return { res: responseOut, alarm: alarm };
	}

	transLocalOrder(dataIn) {
		let response = { ...dataIn };
		try {
			response.id = parseInt(dataIn.order_number);
			response.dateMod = parseInt(dataIn.order_date);
			if (dataIn.order_status === "preparing") {
				response.FormatedStatus = "Preparing";
			} else if (dataIn.order_status === "ready") {
				response.FormatedStatus = "Ready";
			}
			response.status = dataIn.order_status;
			response.local = true;

			return response;
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	transOrder(dataIn) {
		let response = {};
		try {
			response.id = dataIn.id;
			response.first_name = dataIn.billing.first_name;
			response.last_name = dataIn.billing.last_name;
			response.email = dataIn.billing.email;
			response.phone = dataIn.billing.phone;
			response.total = dataIn.total;
			response.status = dataIn.status;
			response.dateMod = Date.parse(dataIn.date_modified);
			response.searched = false;

			if (dataIn.status === "processing") {
				response.FormatedStatus = "Processing";
			} else if (dataIn.status === "preparing-pending") {
				response.FormatedStatus = "Done";
			} else if (dataIn.status === "preparing") {
				response.FormatedStatus = "Preparing";
			} else if (dataIn.status === "ready") {
				response.FormatedStatus = "Ready";
			} else {
				response.FormatedStatus = dataIn.status;
			}

			response.customer_note = dataIn.customer_note;

			for (let i = 0; i < dataIn["meta_data"].length; i++) {
				if (dataIn["meta_data"][i].key === "_local_pickup_time_select") {
					response.pickup_time = dataIn["meta_data"][i].value;

					break;
				}
			}

			let itemsDataIn = dataIn.line_items;
			let itemsArray = [{}];
			let mealBotOpt = 0;
			let mealCanOpt = 0;
			itemsDataIn.map((item, index) => {
				let data = {};
				data.name = item.name;
				data.total = (parseFloat(item.total) + parseFloat(item.total_tax)).toFixed(2);
				data.quantity = item.quantity;

				let itemsOptionsIn = dataIn.line_items[index].meta_data;
				let itemsOptionsArray = [{}];
				let mealOption = "";
				let section = "";
				let sectionCount = 0;

				itemsOptionsIn.map((option, indexOne) => {
					if (indexOne > 0) {
						let optionData = {};

						if (option.key === "_SECTION") {
							section = option.value;
							sectionCount++;
						} else {
							optionData.key = option.key;
							if (option.key === "Make it a Meal!") {
								if (option.value.includes("600ml")) {
									mealOption = "BOT";
									mealBotOpt = mealBotOpt + data.quantity;
								}
								if (option.value.includes("can")) {
									mealOption = "CAN";
									mealCanOpt = mealCanOpt + data.quantity;
								}
							}

							optionData.value = option.value;

							itemsOptionsArray[indexOne - (1 + sectionCount)] = optionData;
						}
					}
					return null;
				});
				data.options = itemsOptionsArray;
				data.mealOption = mealOption;
				data.section = section;

				itemsArray[index] = data;
				return null;
			});

			let { pizzaSection, grillSection, kebabSection, pizzaSectionSequance, grillSectionSequance, kebabSectionSequance, totalSectionCount } = this.sortOrderSection(itemsArray);
			response.pizzaSection = pizzaSection;
			response.grillSection = grillSection;
			response.kebabSection = kebabSection;
			response.pizzaSectionSequance = pizzaSectionSequance;
			response.grillSectionSequance = grillSectionSequance;
			response.kebabSectionSequance = kebabSectionSequance;
			response.totalSectionCount = totalSectionCount;
			response.mealBotOpt = mealBotOpt;
			response.mealCanOpt = mealCanOpt;

			let { daysToPickUp, hoursToPickUp, minutesToPickUp, totalMinutesToPickUp, formatedOrderDate } = this.pickUpTimeOrder(response.pickup_time);

			response.daysToPickUp = daysToPickUp;
			response.hoursToPickUp = hoursToPickUp;
			response.minutesToPickUp = minutesToPickUp;
			response.totalMinutesToPickUp = totalMinutesToPickUp;
			response.formatedOrderDate = formatedOrderDate;

			return response;
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	//PICK UP TIME CALCS ///////////////////////////////////////////////////////////////////////////////////

	pickUpTimeOrder(orderDateIn) {
		let curentDate = new Date();
		let orderDate = new Date();
		let formatedOrderDate = "";

		if (orderDateIn.toString().length < 11) {
			orderDate = new Date(orderDateIn * 1000);
			formatedOrderDate = `${orderDate.getDate()}/${orderDate.getMonth() + 1}/${orderDate.getFullYear()} ${orderDate.toLocaleTimeString("en-AU", { timeStyle: "short" })}`;
		} else {
			let orderDateInYear = parseInt(orderDateIn.substr(6, 4));
			let orderDateInMonth = parseInt(orderDateIn.substr(0, 2)) - 1;
			let orderDateInDay = parseInt(orderDateIn.substr(3, 2));
			let orderDateInHour = 0;
			let underScoreLoc = orderDateIn.indexOf("_");
			let hourTextSize = 2;
			if (underScoreLoc === 11) {
				hourTextSize = 1;
			}
			if (orderDateIn.substr(14 + hourTextSize, 2) === "pm") {
				if (parseInt(orderDateIn.substr(10, hourTextSize)) === 12) {
					orderDateInHour = parseInt(orderDateIn.substr(10, hourTextSize));
				} else {
					orderDateInHour = parseInt(orderDateIn.substr(10, hourTextSize)) + 12;
				}
			} else {
				orderDateInHour = parseInt(orderDateIn.substr(10, hourTextSize));
			}
			let orderDateInMin = parseInt(orderDateIn.substr(11 + hourTextSize, 2));

			//let curentDate = new Date(2020, 8, 27, 19, 40);

			orderDate = new Date(orderDateInYear, orderDateInMonth, orderDateInDay, orderDateInHour, orderDateInMin);
			formatedOrderDate = `${orderDateInDay}/${orderDateInMonth + 1}/${orderDateInYear} ${orderDateIn.substr(10, hourTextSize)}:${orderDateIn.substr(11 + hourTextSize, 2)} ${orderDateIn.substr(
				14 + hourTextSize,
				2
			)}`;
		}

		let timeToPickUp = (orderDate.getTime() - curentDate.getTime()) / 1000;
		let negativeTime = false;
		if (timeToPickUp < 0) {
			timeToPickUp = Math.abs(timeToPickUp);
			negativeTime = true;
		}
		let totalMinutesToPickUp = Math.floor(timeToPickUp / 60);
		let minutesToPickUp = Math.floor((timeToPickUp / 60 / 60 - Math.floor(timeToPickUp / 60 / 60)) * 60);
		let hoursToPickUp = Math.floor((timeToPickUp / 60 / 60 / 24 - Math.floor(timeToPickUp / 60 / 60 / 24)) * 24);
		let daysToPickUp = Math.floor(timeToPickUp / 60 / 60 / 24);

		if (negativeTime === true) {
			daysToPickUp = -1 * daysToPickUp;
			hoursToPickUp = -1 * hoursToPickUp;
			minutesToPickUp = -1 * minutesToPickUp;
			totalMinutesToPickUp = -1 * totalMinutesToPickUp;
		}

		return { daysToPickUp, hoursToPickUp, minutesToPickUp, totalMinutesToPickUp, formatedOrderDate };
	}

	//ITESM SECTIONS ///////////////////////////////////////////////////////////////////////////////////

	sortOrderSection(itemsArray) {
		let pizzaSectionNames = ["Pizza"];
		let grillSectionNames = ["Grill"];
		let kebabSectionNames = ["Kebab"];
		let pizzaSection = [];
		let grillSection = [];
		let kebabSection = [];
		let pizzaSectionSequance = 0;
		let grillSectionSequance = 0;
		let kebabSectionSequance = 0;
		let totalSectionCount = 0;
		itemsArray.map((item) => {
			if (pizzaSectionNames.includes(`${item.section}`)) {
				pizzaSection.push(item);
			} else if (grillSectionNames.includes(`${item.section}`)) {
				grillSection.push(item);
			} else if (kebabSectionNames.includes(`${item.section}`)) {
				kebabSection.push(item);
			} else {
				kebabSection.push(item);
			}

			return null;
		});

		if (kebabSection.length > 0) {
			totalSectionCount = totalSectionCount + 1;
			kebabSectionSequance = totalSectionCount;
		}
		if (pizzaSection.length > 0) {
			totalSectionCount = totalSectionCount + 1;
			pizzaSectionSequance = totalSectionCount;
		}
		if (grillSection.length > 0) {
			totalSectionCount = totalSectionCount + 1;
			grillSectionSequance = totalSectionCount;
		}

		return { pizzaSection, grillSection, kebabSection, pizzaSectionSequance, grillSectionSequance, kebabSectionSequance, totalSectionCount };
	}
}

export default new OrderService();
