import jwtDecode from "jwt-decode";
import http from "./httpService";
import { serverUrlStart } from "../config/config";

const apiEndpoint = serverUrlStart + "/users/login";
const apiGetUser = serverUrlStart + "/users/search/user_id/";
const tokenKey = "auth-token";

http.setJwt(getJwt());

export async function login(email, password) {
  const { data: jwt } = await http.post(apiEndpoint, { email: email, hashed_password: password });
  let jwtDecoded = jwtDecode(jwt);
  if (jwtDecoded.user_role === "Admin") {
    localStorage.setItem(tokenKey, jwt);
  } else {
    throw new Error("User not Admin");
  }
}

export async function getUserData(user_id) {
  return await http.get(apiGetUser + user_id);
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  getUserData,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt
};
