import React, { useEffect, useRef } from "react";
//import { Howl } from "howler";
import io from "socket.io-client";
import OrderService from "../services/orderService";
import TvReady from "../components/tvReady";
//import TvPrep from "../components/tvPrep";

//import OrdersList from "../components/ordersList";
//import TvCont from "../components/tvCont";
//import SingleOrderDisp from "../components/singleOrderDisp";

//const socket = io.connect("http://192.168.1.112:5002", {
//	query: { token: localStorage.getItem("auth-token") },
//	path: "/kporderapi/sok",
//});

//const socket = io.connect("https://kporder.pizzapalace.com.au", {
//	query: { token: localStorage.getItem("auth-token") },
//	path: "/kporderapi/sok",
//});

/////////////////////////////////////////////////////////////final before build
const socket = io.connect({
	query: { token: localStorage.getItem("auth-token") },
	path: "/kporderapi/sok",
});

const Tv = ({ user }) => {
	const [orders, setOrders] = React.useState([{}]);
	const [locOrders, setLocOrders] = React.useState([{}]);

	const stateRef = useRef();
	stateRef.current = orders;

	const locStateRef = useRef();
	locStateRef.current = locOrders;

	async function order() {
		let res = await OrderService.getOrders();
		return res;
	}

	async function locOrder() {
		let res = await OrderService.getLocOrders();
		return res;
	}

	useEffect(() => {
		async function fetchNewOrders(data) {
			try {
				const { newOrder } = await OrderService.addOneOrder(stateRef.current, data, false);
				if (newOrder !== null && newOrder !== undefined) {
					//console.log(newOrder);
					let { res } = await OrderService.refreshOrderTimes(newOrder);
					setOrders(res);
				}
			} catch (e) {
				console.log(e);
			}
		}

		async function fetchNewLocalOrders(data) {
			try {
				const newOrder = await OrderService.addOneLocalOrder(locStateRef.current, data);
				if (newOrder !== null && newOrder !== undefined) {
					setLocOrders(newOrder);
				}
			} catch (e) {
				console.log(e);
			}
		}

		async function fetchData() {
			let { res } = OrderService.refreshOrderTimes(await order());
			let locOrderRes = await locOrder();
			setLocOrders(locOrderRes);
			setOrders(res);
		}

		fetchData();
		socket.on("NewKPOrder", async (data) => {
			await fetchNewOrders(data);
		});

		socket.on("LocalOrdUpd", async (data) => {
			await fetchNewLocalOrders(data);
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	return (
		<React.Fragment>
			{user.user_id && (
				<React.Fragment>
					<div className="row">
						<div className="col-12  text-center text-light bg-dark">
							<h1 className="mb-0" style={{ fontSize: "12em" }}>
								<strong>TAKEAWAY ORDERS</strong>
							</h1>
						</div>

						<div className="col-12 text-center text-light bg-dark">
							<h1 className="mt-0 mb-4" style={{ fontSize: "3.8em" }}>
								PLEASE WAIT HERE UNTIL YOUR ORDER NUMBER IS MARKED AS READY
							</h1>
						</div>
					</div>
					<div className="row pt-4">
						<div className="col-6 p-4 pl-5">
							<div className="card border-dark" style={{ borderWidth: "0.15em", borderRadius: "3.5em" }}>
								<div className="card-header text-center text-white bg-dark border-bottom-0 py-5" style={{ borderTopLeftRadius: "3em", borderTopRightRadius: "3em" }}>
									<strong>
										<h1 style={{ fontSize: "5em" }}>ONLINE ORDERS</h1>
									</strong>
								</div>
								<div className="card-body px-4 pb-0 pt-0 bg-dark" style={{ borderBottomLeftRadius: "3em", borderBottomRightRadius: "3em" }}>
									<div className="card mx-2 mt-2" style={{ borderRadius: "1em", marginBottom: "5em" }}>
										<div className="card-header text-light text-center bg-success border-bottom-0" style={{ borderTopLeftRadius: "1em", borderTopRightRadius: "1em" }}>
											<h1 style={{ fontSize: "4em" }}>
												<strong>READY</strong> TO PICK UP
											</h1>
										</div>
										<div className="card-body text-light px-5 overflow-hidden " style={{ height: "170em", borderBottomLeftRadius: "1em", borderBottomRightRadius: "1em", backgroundColor: "#001b0c" }}>
											<TvReady orders={orders}></TvReady>
										</div>
									</div>
									{/*
									<div className="card m-2 mt-5" style={{ borderRadius: "1em" }}>
										<div className="card-header text-light text-center bg-info" style={{ borderTopLeftRadius: "1em", borderTopRightRadius: "1em" }}>
											<h1 style={{ fontSize: "4em" }}>
												<strong>PREPARING</strong>
											</h1>
										</div>
										<div className="card-body text-dark px-5 overflow-hidden " style={{ height: "42.5em", borderBottomLeftRadius: "1em", borderBottomRightRadius: "1em", backgroundColor: "#082f35" }}>
											<TvPrep orders={orders}></TvPrep>
										</div>
									</div>
									*/}
								</div>
							</div>
						</div>
						<div className="col-6 p-4 pr-5 ">
							<div className="card border-secondary" style={{ borderWidth: "0.15em", borderRadius: "3.5em" }}>
								<div className="card-header text-center text-white bg-secondary border-bottom-0 py-5 " style={{ borderTopLeftRadius: "3em", borderTopRightRadius: "3em" }}>
									<strong>
										<h1 style={{ fontSize: "5em" }}>IN-STORE ORDERS</h1>
									</strong>
								</div>
								<div className="card-body px-4 pb-0 pt-0 bg-secondary" style={{ borderBottomLeftRadius: "3em", borderBottomRightRadius: "3em" }}>
									<div className="card mx-2 mt-2" style={{ borderRadius: "1em", marginBottom: "5em" }}>
										<div className="card-header text-light  text-center bg-success border-bottom-0" style={{ borderTopLeftRadius: "1em", borderTopRightRadius: "1em" }}>
											<h1 style={{ fontSize: "4em" }}>
												<strong>READY</strong> TO PICK UP
											</h1>
										</div>
										<div className="card-body text-light px-5 overflow-hidden " style={{ height: "170em", borderBottomLeftRadius: "1em", borderBottomRightRadius: "1em", backgroundColor: "#748b86" }}>
											<TvReady orders={locOrders}></TvReady>
										</div>
									</div>
									{/*
									<div className="card m-2 mt-5" style={{ borderRadius: "1em" }}>
										<div className="card-header text-light text-center bg-info" style={{ borderTopLeftRadius: "1em", borderTopRightRadius: "1em" }}>
											<h1 style={{ fontSize: "4em" }}>
												<strong>PREPARING</strong>
											</h1>
										</div>
										<div className="card-body text-dark px-5 overflow-hidden " style={{ height: "42.5em", borderBottomLeftRadius: "1em", borderBottomRightRadius: "1em", backgroundColor: "#6e969c" }}>
											<TvPrep orders={locOrders}></TvPrep>
										</div>
									</div>
									*/}
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 text-center">
							<h1 className="mt-3 " style={{ fontSize: "4em" }}>
								www.kebabpalace.com.au
							</h1>
						</div>
					</div>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default Tv;
