import React, { useEffect, useRef } from "react";
import { Howl } from "howler";
import io from "socket.io-client";
import OrderService from "../services/orderService";
import RefreshOrders from "../components/refreshOrders";
import OrdersList from "../components/ordersList";
import TvCont from "../components/tvCont";
import SingleOrderDisp from "../components/singleOrderDisp";

//const socket = io.connect("http://192.168.1.112:5002", {
//	query: { token: localStorage.getItem("auth-token") },
//	path: "/kporderapi/sok",
//});

//const socket = io.connect("https://kporder.pizzapalace.com.au", {
//	query: { token: localStorage.getItem("auth-token") },
//	path: "/kporderapi/sok",
//});

/////////////////////////////////////////////////////////////final before build
const socket = io.connect({
	query: { token: localStorage.getItem("auth-token") },
	path: "/kporderapi/sok",
});

socket.on("kpmessage", async (data) => {
	console.log(data);
});

const Home = ({ user, alarmStatus }) => {
	const [orders, setOrders] = React.useState([{}]);
	const [locOrders, setLocOrders] = React.useState([{}]);
	const [selectedOrder, setSelectedOrder] = React.useState({});
	const [selectedLocalOrder, setSelectedLocalOrder] = React.useState({});
	const [sound, setSound] = React.useState({});

	const stateRef = useRef();
	stateRef.current = orders;

	const locStateRef = useRef();
	locStateRef.current = locOrders;

	let alarm = useRef(false);

	if (Object.keys(sound).length === 0 && sound.constructor === Object) {
		setSound(
			new Howl({
				src: ["audio/AlertSound.mp3"],

				loop: true,
			})
		);
	}
	const stateRefSound = useRef();
	stateRefSound.current = sound;

	const orderPageStyle = () => {
		if (alarm.current === false) {
			return "orderSeconPage";
		} else {
			return "orderSeconPageAlarm";
		}
	};

	const handleAlarm = (data) => {
		alarm.current = data;
	};

	const handleSound = (data, sound) => {
		if (data === true && Object.keys(sound).length !== 0) {
			if (sound.playing() === false) {
				sound.play();
			}
		} else {
			if (Object.keys(sound).length !== 0) {
				sound.stop();
			}
		}
	};

	alarmStatus(alarm.current);

	async function order() {
		let res = await OrderService.getOrders();

		return res;
	}

	async function locOrder() {
		let res = await OrderService.getLocOrders();

		return res;
	}

	const selectedOrderFunc = (id, local = false) => {
		if (!local) {
			setSelectedOrder(OrderService.getOrderById(stateRef.current, id));
			setSelectedLocalOrder({});
		} else {
			setSelectedLocalOrder(OrderService.getOrderById(locStateRef.current, id));
			setSelectedOrder({});
		}
	};

	const acceptOrderFunc = async (id, status, FormatedStatus) => {
		handleAlarm(false);
		handleSound(false, stateRefSound.current);
		let newOrder = await OrderService.acceptOrder(stateRef.current, id, status, FormatedStatus);
		if (newOrder !== null && newOrder !== undefined) {
			let { res, alarm } = OrderService.refreshOrderTimes(newOrder);
			setOrders(res);
			handleAlarm(alarm);
			handleSound(alarm, stateRefSound.current);
			setSelectedOrder(stateRef.current[0]);
			setOrders(res);
		}
	};

	const acceptTvOrderFunc = async (id, status, FormatedStatus, local) => {
		if (!local) {
			let newOrder = await OrderService.acceptOrder(stateRef.current, id, status, FormatedStatus);
			if (newOrder !== null && newOrder !== undefined) {
				let { res } = OrderService.refreshOrderTimes(newOrder);
				setOrders(res);
				setSelectedOrder({});
				setSelectedLocalOrder({});
			}
		} else if (local) {
			let newLocalOrder = await OrderService.acceptLocalOrder(locStateRef.current, id, status, FormatedStatus);
			if (newLocalOrder !== null && newLocalOrder !== undefined) {
				setLocOrders(newLocalOrder);
				setSelectedOrder({});
				setSelectedLocalOrder({});
			}
		}
	};

	const addLocalOrder = async (id) => {
		let res = await OrderService.addLocOrder(id);

		if (res) {
			if (Object.keys(locStateRef.current[0]).length > 0) {
				const orderById = (order) => order.order_number === res.order_number;
				let orderByIdIndex = locStateRef.current.findIndex(orderById);
				if (orderByIdIndex < 0) {
					let newLocOrder = [...locStateRef.current, res];
					setLocOrders(newLocOrder);
				}
			} else {
				setLocOrders([res]);
			}
		}
	};

	const orderSearch = async (oderSearchId) => {
		let res = OrderService.getOrderById(stateRef.current, oderSearchId);
		if (res !== undefined) {
			setSelectedOrder(res);
		} else {
			let newOrder = await OrderService.fetchOrderById(stateRef.current, oderSearchId);
			if (newOrder !== null && newOrder !== undefined) {
				setOrders(newOrder);
				setSelectedOrder(OrderService.getOrderById(stateRef.current, oderSearchId));
			}
		}
	};

	useEffect(() => {
		async function fetchNewOrders(data) {
			try {
				const { newOrder, orderUpdated } = await OrderService.addOneOrder(stateRef.current, data);
				if (newOrder !== null && newOrder !== undefined) {
					//console.log(newOrder);
					let { res, alarm } = await OrderService.refreshOrderTimes(newOrder);
					setOrders(res);
					handleAlarm(alarm);
					handleSound(alarm, stateRefSound.current);
					if (orderUpdated === false) {
						setSelectedOrder(stateRef.current[0]);
					}
				}
			} catch (e) {
				console.log(e);
			}
		}

		async function fetchData() {
			let { res, alarm } = OrderService.refreshOrderTimes(await order());
			let locOrderRes = await locOrder();
			setLocOrders(locOrderRes);
			setOrders(res);

			handleAlarm(alarm);
			handleSound(alarm, stateRefSound.current);
		}

		const interval = setInterval(() => {
			if (typeof stateRef.current !== "undefined" && stateRef.current.length > 1) {
				let { res, alarm } = OrderService.refreshOrderTimes(stateRef.current);
				setOrders(res);
				handleAlarm(alarm);
				handleSound(alarm, stateRefSound.current);
			}
		}, 60000);

		fetchData();
		socket.on("NewKPOrder", async (data) => {
			await fetchNewOrders(data);
		});

		return () => {
			clearInterval(interval);
			stateRefSound.current.unload();
			socket.disconnect();
		};
	}, []);

	return (
		<React.Fragment>
			{user.user_id && (
				<div className={"row " + orderPageStyle()}>
					<div className="col-md-4">
						<RefreshOrders orderSearch={orderSearch}></RefreshOrders>
						<OrdersList orders={orders} selectedOrderFunc={selectedOrderFunc} selectedOrderId={selectedOrder.id}></OrdersList>
					</div>
					<div className="col-md-8">
						<TvCont
							orders={orders}
							locOrders={locOrders}
							selectedOrderFunc={selectedOrderFunc}
							addLocalOrder={addLocalOrder}
							selectedOrder={selectedOrder}
							selectedLocalOrder={selectedLocalOrder}
							acceptTvOrderFunc={acceptTvOrderFunc}
						></TvCont>

						<SingleOrderDisp selectedOrder={selectedOrder} acceptOrderFunc={acceptOrderFunc}></SingleOrderDisp>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

export default Home;
