import React, { useEffect } from "react";
//import ItemsList from "../components/itemsList";
//import Loader from "react-loader-spinner";
import TvOrderList from "./tvOrdList";
import LocalOrders from "../components/localOrders";

const TvCont = (props) => {
	const [tvOrders, setTvOrders] = React.useState([{}]);

	useEffect(() => {
		let newTvOrders = [];
		if (Object.keys(props.orders[0]).length > 0) {
			for (let i = 0; i < props.orders.length; i++) {
				if (props.orders[i].status === "preparing" || props.orders[i].status === "ready") {
					newTvOrders[newTvOrders.length] = props.orders[i];
				}
			}
		}

		if (Object.keys(props.locOrders[0]).length > 0 && newTvOrders.length > 0) {
			newTvOrders = [...newTvOrders, ...props.locOrders];
		} else if (Object.keys(props.locOrders[0]).length > 0 && newTvOrders.length === 0) {
			newTvOrders = [...props.locOrders];
		} else if (Object.keys(props.locOrders[0]).length === 0 && newTvOrders.length === 0) {
			newTvOrders = [{}];
		}

		newTvOrders.sort(function (a, b) {
			return a.dateMod - b.dateMod;
		});

		setTvOrders(newTvOrders);
	}, [props.orders, props.locOrders]);

	return (
		<div className="TvSection">
			<div className="row g-1">
				<div className="col-4 p-0 px-3">
					<div className="card border border-0 PreparingSection ">
						<div className="card-header border border-0 p-1 pl-3 text-light bg-info ">
							<strong>PREPARING</strong> SECTION - Food is Cooking
						</div>
						<div className="card-body pt-3 px-3" style={{ backgroundColor: "#002e35" }}>
							<div className="">
								<TvOrderList {...props} tvOrders={tvOrders} status="preparing"></TvOrderList>
							</div>
						</div>
					</div>
				</div>
				<div className="col-8 p-0">
					<div className="card border border-0 PreparingSection ">
						<div className="card-header border border-0 p-1 pl-3 text-light bg-primary ">
							<strong>READY</strong> SECTION - Waiting to be Picked-Up
						</div>

						<div className="card-body pt-3 " style={{ backgroundColor: "#001e3e" }}>
							<div className="row pr-2 ">
								<div className="col-8 pr-3">
									<TvOrderList {...props} tvOrders={tvOrders} status="ready"></TvOrderList>
								</div>
								<div className="col-4 pr-4">
									<LocalOrders {...props} tvOrders={tvOrders}></LocalOrders>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TvCont;
