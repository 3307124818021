import { useEffect } from "react";
const LogOut = () => {
  useEffect(() => {
    localStorage.removeItem("auth-token");
    window.location = "/";
  }, []);
  return null;
};

export default LogOut;
