import React from "react";
import { useForm } from "react-hook-form";

const RefreshOrders = ({ orderSearch }) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    orderSearch(parseInt(data.orderNumber));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="col-sm-6  mb-3">
          <label className="sr-only" htmlFor="number">
            Order Number
          </label>
          <div className="input-group mb-2 mr-sm-2">
            <div className="input-group-prepend">
              <div className="input-group-text">#</div>
            </div>
            <input type="number" name="orderNumber" className="keyboard form-control " id="number" placeholder="Order Number" autoComplete="off" ref={register({ required: true })}></input>
          </div>
        </div>
        <div className="col-sm-2  mb-3">
          <button type="submit" className="btn btn-outline-secondary mb-2">
            <i className="fas fa-search"></i>
          </button>
        </div>

        <div className="col-sm-4  mb-3 d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mb-2 ml-5" onClick={() => window.location.reload()}>
            <i className="fas fa-sync-alt"></i> Refresh
          </button>
        </div>
      </div>
    </form>
  );
};

export default RefreshOrders;
