import React, { useEffect, useRef } from "react";
import { Howl } from "howler";

const TvReady = (props) => {
	const [filteredOrders, setFilteredOrders] = React.useState([{}]);
	const [firstOrd, setFirstOrd] = React.useState({});
	const [showNewOrdAnim, setNewOrdAnim] = React.useState(false);
	const [numberOfOrders, setNumberOfOrders] = React.useState(0);
	const [timer, setTimer] = React.useState(setTimeout(function () {}, 10000));
	const [sound, setSound] = React.useState({});

	if (Object.keys(sound).length === 0 && sound.constructor === Object) {
		setSound(
			new Howl({
				src: ["audio/Ready.mp3"],
			})
		);
	}
	const stateRefSound = useRef();
	stateRefSound.current = sound;

	const handleSound = (data, sound) => {
		if (data === true && Object.keys(sound).length !== 0) {
			if (sound.playing() === false) {
				sound.play();
			}
		} else {
			if (Object.keys(sound).length !== 0) {
				sound.stop();
			}
		}
	};

	const numberOfOrdersLimit = 21;

	useEffect(() => {
		const handleFlash = (data) => {
			clearTimeout(timer);
			handleSound(true, stateRefSound.current);

			setNewOrdAnim(true);
			setFirstOrd(data);
			setTimer(
				setTimeout(function () {
					setNewOrdAnim(false);
					handleSound(false, stateRefSound.current);
				}, 10000)
			);
		};

		let newFilteredOrders = [];
		if (Object.keys(props.orders[0]).length > 0) {
			for (let i = 0; i < props.orders.length; i++) {
				if (props.orders[i].status === "ready") {
					newFilteredOrders[newFilteredOrders.length] = props.orders[i];
				}
			}
		}

		if (newFilteredOrders.length === 0) {
			newFilteredOrders = [{}];
		}

		newFilteredOrders.sort(function (a, b) {
			return b.dateMod - a.dateMod;
		});

		if (Object.keys(firstOrd).length > 0 && Object.keys(newFilteredOrders[0]).length > 0) {
			if (firstOrd.id !== newFilteredOrders[0].id) {
				handleFlash(newFilteredOrders[0]);
			}
		} else if (Object.keys(firstOrd).length === 0 && Object.keys(newFilteredOrders[0]).length > 0) {
			handleFlash(newFilteredOrders[0]);
		}
		setNumberOfOrders(newFilteredOrders.length);
		setFilteredOrders(newFilteredOrders);
	}, [props.orders, firstOrd, timer]);

	const genOrder = (order, index) => {
		if (order && Object.keys(order).length > 0) {
			if (index === 0) {
				return (
					<div key={index} className="col-12">
						<div className={` text-dark text-center mt-4 mb-4 mx-3 py-5 border  ReadyOrderTv ${showNewOrdAnim ? "NewTvOrder" : ""}`}>
							<h1 style={{ fontSize: "8em" }}>
								#<strong>{order.id}</strong>
							</h1>
						</div>
					</div>
				);
			}
			if (index > 0) {
				return (
					<div key={index} className={`${numberOfOrders > numberOfOrdersLimit ? "col-4" : "col-6"}`}>
						<div className={` text-dark text-center border ReadyOrderTv  ${numberOfOrders > numberOfOrdersLimit ? "my-3 mx-1 py-3" : " my-4 mx-3 py-5 "}`}>
							<h1 style={{ fontSize: `${numberOfOrders > numberOfOrdersLimit ? "2.5em" : "4em"}` }}>
								#<strong>{order.id}</strong>
							</h1>
						</div>
					</div>
				);
			}
		}
	};

	return <div className="row">{filteredOrders && filteredOrders.map((order, index) => genOrder(order, index))}</div>;
};

export default TvReady;
