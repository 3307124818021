import React, { useEffect } from "react";

const OrderSmallRow = (props) => {
	const [timeFromChange, setTimeFromChange] = React.useState({});
	const [timeCol, setTimeCol] = React.useState("#125003");

	let time = Math.floor((Date.now() - props.order.dateMod) / (1000 * 60));

	useEffect(() => {
		setTimeFromChange(time);
		if (time >= 20 && time < 30) {
			setTimeCol("#DCA000");
		} else if (time >= 30) {
			setTimeCol("#CD3900");
		} else {
			setTimeCol("#125003");
		}
	}, [time, props.order]);

	function orderStatStyle(order) {
		if (props.order.local === true) {
			return "localItem";
		} else if (!props.order.local && order.status === "preparing") {
			return "list-group-item-info";
		} else if (!props.order.local && order.status === "ready") {
			return "list-group-item-primary";
		} else if (!props.order.local && order.status === "preparing-pending") {
			return "list-group-item-success";
		}
	}

	function orderActiveStyle(id, selectedOrder, selectedLocalOrder) {
		if ((id === selectedOrder.id && !props.order.local) || (id === selectedLocalOrder.id && props.order.local)) {
			return " active border-1 border-light ";
		} else {
			return " border-1 border-dark ";
		}
	}

	const handleClick = (id) => {
		if (!props.order.local) {
			props.selectedOrderFunc(id);
		} else {
			props.selectedOrderFunc(id, true);
		}
	};

	return (
		<div className={`${props.ordStatus === "preparing" ? "col-4" : "col-3"}  rounded mb-3 px-2`}>
			<button
				type="button"
				className={"list-group-item list-group-item-action p-0 border  " + orderStatStyle(props.order) + orderActiveStyle(props.order.id, props.selectedOrder, props.selectedLocalOrder)}
				onClick={() => handleClick(props.order.id)}
			>
				{props.order.local ? (
					<div className="p-0 py-2 text-center mb-2  mx-0 rounded-top text-light " style={{ fontSize: "10pt", backgroundColor: "#945ea1" }}>
						IN-STORE
					</div>
				) : (
					<div className="p-0 py-2 text-center mb-2  mx-0 text-dark rounded-top bg-white " style={{ fontSize: "10pt" }}>
						ONLINE
					</div>
				)}
				<div className="text-center my-2 ">
					<h4 style={{ lineHeight: "18pt" }}>#{props.order.id}</h4>
				</div>
				<div className="p-0 text-center text-light mx-3 rounded-top mt-2 " style={{ fontSize: "10pt", backgroundColor: `${timeCol}` }}>
					<span style={{ fontWeight: "bolder" }}>{`${timeFromChange}`} </span> Min
				</div>
			</button>
		</div>
	);
};

export default OrderSmallRow;
