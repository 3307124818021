import React from "react";
import Loader from "react-loader-spinner";

const LocalOrders = (props) => {
	const [locNum, setLocNum] = React.useState(0);
	const [showWait, setShowWait] = React.useState(false);

	const handleClick = (num) => {
		if (locNum === 0) {
			setLocNum(num);
		} else if (locNum <= 999) {
			let newNum = locNum * 10 + num;
			setLocNum(newNum);
		}
	};

	const handleDel = () => {
		if (locNum !== 0) {
			if (locNum < 10) {
				setLocNum(0);
			} else {
				let newNum = parseInt(locNum.toString().slice(0, -1));

				setLocNum(newNum);
			}
		}
	};

	const handleGoClick = () => {
		setShowWait(true);
		////////send to server

		props.addLocalOrder(locNum);
		setLocNum(0);

		setTimeout(function () {
			setShowWait(false);
		}, 1000);
	};

	return (
		<div className="row">
			<div className="col-8 pt-0 px-1 pb-1  ">
				<div className="text-dark  rounded text-center h-100" style={{ backgroundColor: "#f9e3ff" }}>
					<h1 className="pt-1">{locNum}</h1>
				</div>
			</div>
			<div className="col-4 pt-0 px-1 pb-1 ">
				<button type="button" className="btn btn-light  btn-lg btn-block pt-1 pb-1 h-100 border-0 numPadClear " onClick={() => handleDel()}>
					<h2 className="mt-1">{"<"}</h2>
				</button>
			</div>
			<div className="col-4 p-1">
				<button type="button" className="btn btn-dark  btn-lg btn-block pt-1 pb-1 numPadBut " onClick={() => handleClick(1)}>
					<h2 className="mt-1">1</h2>
				</button>
			</div>
			<div className="col-4 p-1">
				<button type="button" className="btn btn-dark  btn-lg btn-block pt-1 pb-1 numPadBut " onClick={() => handleClick(2)}>
					<h2 className="mt-1">2</h2>
				</button>
			</div>
			<div className="col-4 p-1">
				<button type="button" className="btn btn-dark  btn-lg btn-block pt-1 pb-1 numPadBut " onClick={() => handleClick(3)}>
					<h2 className="mt-1">3</h2>
				</button>
			</div>
			<div className="col-4 p-1">
				<button type="button" className="btn btn-dark  btn-lg btn-block pt-1 pb-1 numPadBut " onClick={() => handleClick(4)}>
					<h2 className="mt-1">4</h2>
				</button>
			</div>
			<div className="col-4 p-1">
				<button type="button" className="btn btn-dark  btn-lg btn-block pt-1 pb-1 numPadBut " onClick={() => handleClick(5)}>
					<h2 className="mt-1">5</h2>
				</button>
			</div>
			<div className="col-4 p-1">
				<button type="button" className="btn btn-dark  btn-lg btn-block pt-1 pb-1 numPadBut " onClick={() => handleClick(6)}>
					<h2 className="mt-1">6</h2>
				</button>
			</div>
			<div className="col-4 p-1">
				<button type="button" className="btn btn-dark  btn-lg btn-block pt-1 pb-1 numPadBut " onClick={() => handleClick(7)}>
					<h2 className="mt-1">7</h2>
				</button>
			</div>
			<div className="col-4 p-1">
				<button type="button" className="btn btn-dark  btn-lg btn-block pt-1 pb-1 numPadBut " onClick={() => handleClick(8)}>
					<h2 className="mt-1">8</h2>
				</button>
			</div>
			<div className="col-4 p-1">
				<button type="button" className="btn btn-dark  btn-lg btn-block pt-1 pb-1 numPadBut " onClick={() => handleClick(9)}>
					<h2 className="mt-1">9</h2>
				</button>
			</div>
			<div className="col-4 p-1">
				<button type="button" className="btn btn-dark  text-dark btn-lg btn-block pt-1 pb-1" disabled>
					<h2 className="mt-1">-</h2>
				</button>
			</div>
			<div className="col-4 p-1">
				<button type="button" className="btn btn-dark  btn-lg btn-block pt-1 pb-1 numPadBut " onClick={() => handleClick(0)}>
					<h2 className="mt-1">0</h2>
				</button>
			</div>
			<div className="col-4 p-1">
				<button type="button" className="btn btn-dark text-dark btn-lg btn-block pt-1 pb-1" disabled>
					<h2 className="mt-1">-</h2>
				</button>
			</div>
			<div className="col-12 p-1">
				{showWait === true && (
					<React.Fragment>
						<button type="button" className="btn  btn-lg btn-block text-light py-3" style={{ backgroundColor: "#b825da" }} disabled>
							<Loader className="py-0" type="ThreeDots" color="white" height={20} width={100} timeout={10000} />
						</button>
					</React.Fragment>
				)}
				{locNum > 0 && showWait === false && (
					<button type="button" className="btn  btn-lg btn-block text-light py-3 locBut " onClick={() => handleGoClick()}>
						<i className="fas fa-plus-circle"></i> Add In-Store Ord.
					</button>
				)}
				{locNum === 0 && showWait === false && (
					<button type="button" className="btn   btn-lg btn-block text-light py-3" style={{ backgroundColor: "#b825da" }} disabled>
						<i className="fas fa-plus-circle"></i> Add In-Store Ord.
					</button>
				)}
			</div>
		</div>
	);
};

export default LocalOrders;
