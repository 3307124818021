import React from "react";
import OrderSmallRow from "./orderSmallRow";
import Loader from "react-loader-spinner";

const TvOrderList = (props) => {
	const [showWait, setShowWait] = React.useState(false);

	const handleAcceptClick = () => {
		setShowWait(true);
		setTimeout(function () {
			setShowWait(false);
		}, 5000);
		if (props.selectedOrder.status === props.status) {
			if (props.status === "preparing") {
				props.acceptTvOrderFunc(props.selectedOrder.id, "ready", "Ready", false);
			} else if (props.status === "ready") {
				props.acceptTvOrderFunc(props.selectedOrder.id, "preparing-pending", "Done", false);
			}
		} else if (props.selectedLocalOrder.status === props.status) {
			if (props.status === "preparing") {
				props.acceptTvOrderFunc(props.selectedLocalOrder.id, "ready", "Ready", true);
			} else if (props.status === "ready") {
				props.acceptTvOrderFunc(props.selectedLocalOrder.id, "done", "Done", true);
			}
		}
	};

	const genOrder = (order, index) => {
		if (order && Object.keys(order).length > 0) {
			if (order.status === props.status) {
				return <OrderSmallRow key={index} order={order} {...props} ordStatus={props.status}></OrderSmallRow>;
			}
		}
	};

	return (
		<div className="">
			<div className=" mb-2 pt-0">
				{showWait === true && (
					<React.Fragment>
						{props.status === "preparing" ? (
							<button type="button" className="btn btn-info  btn-lg btn-block py-3" disabled>
								<Loader className="py-0" type="ThreeDots" color="white" height={20} width={100} timeout={10000} />
							</button>
						) : (
							<button type="button" className="btn btn-primary  btn-lg btn-block py-3" disabled>
								<Loader className="py-0" type="ThreeDots" color="white" height={20} width={100} timeout={10000} />
							</button>
						)}
					</React.Fragment>
				)}
				{(props.selectedOrder.status === props.status || props.selectedLocalOrder.status === props.status) && showWait === false && (
					<React.Fragment>
						{props.status === "preparing" ? (
							<button type="button" className="btn btn-info  btn-lg btn-block py-3" onClick={() => handleAcceptClick()}>
								<i className="fas fa-shopping-bag"></i> Mark Ord. as <strong>Ready</strong>
							</button>
						) : (
							<button type="button" className="btn btn-primary  btn-lg btn-block py-3" onClick={() => handleAcceptClick()}>
								<i className="fas fa-check-circle"></i> Mark Ord. as <strong>Done</strong>
							</button>
						)}
					</React.Fragment>
				)}
				{props.selectedOrder.status !== props.status && props.selectedLocalOrder.status !== props.status && showWait !== true && (
					<React.Fragment>
						{props.status === "preparing" ? (
							<button type="button" className="btn btn-info  btn-lg btn-block py-3" disabled>
								<i className="fas fa-shopping-bag"></i> Mark Ord. as <strong>Ready</strong>
							</button>
						) : (
							<button type="button" className="btn btn-primary  btn-lg btn-block py-3" disabled>
								<i className="fas fa-check-circle"></i> Mark Ord. as <strong>Done</strong>
							</button>
						)}
					</React.Fragment>
				)}
			</div>
			<div className="PrepOrdList pt-2 px-1">
				<div className="row px-1 ">{props.tvOrders && props.tvOrders.map((order, index) => genOrder(order, index))}</div>
			</div>
		</div>
	);
};

export default TvOrderList;
