import React, { useEffect } from "react";
import { useIsOnline } from "react-use-is-online";

const CheckOnline = ({ setState }) => {
	let { isOnline } = useIsOnline();

	useEffect(() => {
		setState(isOnline);
	}, [isOnline, setState]);

	return <React.Fragment></React.Fragment>;
};

export default CheckOnline;
